<template>
  <div>
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <inquiry-and-quotation-tab :tableData="tableData"></inquiry-and-quotation-tab>
    <div class="xbjtablepage" v-if="tableData&&tableData.length>0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size = pageSize
        layout="total, prev, pager, next, jumper"
        :total = total>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
export default {
  name: 'InquiryAndQuotation',
  components: Component.components,
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      tableField: tableField,
      tableData: tableData,
      newsQueryData: [],
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.InquiryList()
  },
  mounted () {
    // 请求经营范围字典
    var newOption = []
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((res) => {
      res.forEach(item => {
        newOption.push({
          label: item.companyDesc,
          value: item.businessScope
        })
      })
      this.childData.searchList[2].option = newOption
    })
  },
  methods: {
    InquiryList () {
      let ksTime = ''
      let jsTime = ''
      if (this.childData.searchData.createDate) {
        ksTime = dayjs(this.childData.searchData.createDate[0]).format('YYYY-MM-DD HH:mm:ss')
        jsTime = dayjs(this.childData.searchData.createDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        code: this.childData.searchData.code,
        itemName: this.childData.searchData.itemName,
        businessScope: this.childData.searchData.businessScope,
        status: this.childData.searchData.status,
        type: this.childData.searchData.type,
        startTime: ksTime,
        endTime: jsTime,
        buyer:this.childData.searchData.buyer,
        supplier:this.childData.searchData.supplier,
      }
      request('/api/inquiry/list?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    // 发布询报价
    buttonClick: function (data) {
      if (data.openView === 'openView') {
        this.$router.push({ params: { viewName: data.alias }, query: data.query })
        this.$emit('OpenView', { openView: data.alias })
      }
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.InquiryList()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.InquiryList()
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.InquiryList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.InquiryList()
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped>
  .xbjtablepage{
    padding: 18px 0;
    text-align: center;
  }
</style>
