export var SearchList = [
  { labe: '询价编号', code: 'code', type: 'input' },
  { labe: '询价描述', code: 'itemName', type: 'input' },
  {
    labe: '经营范围',
    code: 'businessScope',
    type: 'select',
    option: []
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '待报价', value: '1' },
      { label: '待审批', value: '2' },
      { label: '待处理', value: '3' },
      { label: '废弃', value: '5' },
      { label: '已结束', value: '4' },
      { label: '草稿', value: '0' }
    ]
  },
  {
    labe: '询价类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '单一供应商询价', value: '1' },
      { label: '多家供应商询价', value: '2' },
      { label: '普通竞价', value: '3' },
      { label: '反向竞价', value: '4' }
    ]
  },
  { labe: '采购员', code: 'buyer', type: 'input' },
  { labe: '受邀供应商', code: 'supplier', type: 'input' },
  {
    labe: '发布日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  code: '',
  itemName: '',
  businessScope: '',
  status: '',
  type: '',
  createDate: '',
  buyer:'',
  supplier:'',
}

export var tableField = [
  { label: '询价编号', code: 'proNum', type: 'input', width: '' },
  { label: '询价描述', code: 'proName', type: 'input', width: '' },
  { label: '经营范围', code: 'natureBusiness', type: 'input', width: '' },
  { label: '询价类型', code: 'inquiryType', type: 'input', width: '' },
  { label: '发布日期', code: 'dateOfIssue', type: 'input', width: '' },
  { label: '采购员', code: 'buyer', type: 'input', width: '' },
  { label: '状态', code: 'status', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '200',
    type: 'action',
    actionData: [
      {
        actionLabel: '废弃',
        id: '10'
      },
      {
        actionLabel: '复制',
        id: '11'
      },
      {
        actionLabel: '修改',
        id: '12'
      },
      {
        actionLabel: '查看',
        id: '13'
      },
      {
        actionLabel: '编辑',
        id: '14'
      }
    ]
  }
]

export var tableData = []
export var buttonData = ['我的发起', '待我审批', '我已审批', '全部']

export var formData = {
  bidSupplierList: []
}
